<template>
  <!-- <div class="container-fluid header not-print">
        <div class="row primary-header pl-4 not-print">
            <div class="col-md-2 col-lg-1 pr-0 pl-0">
                <router-link :to="{ name: 'monitors-page', params: { tab: 'cotacoes' } }">
                    <img class="logo" src="/images/logotipo.png"/>
                </router-link>
            </div>
            <div class="col pl-4">
                <span>
                    <i class="fas fa-user-cog"></i>
                    Olá, <span>{{ user.user_name | capitalize }}</span>
                </span>
                <cc-breadcrumb v-bind:breadcrumbs="breadcrumbs" classes="gray"></cc-breadcrumb>
            </div>
        </div>
        <div class="row">
            <div  class="col-md-2 offset-md-8 ">
                <div :hidden="isHidden" class="menuDropDown text-center text-light " @mouseover="isHidden = false" @mouseout="isHidden = true">
                    <div class="d-flex flex-column menuLink">
                        <router-link class="menuLink" tag="span" :to="{ name: 'monitors-page', params: { tab: 'pedidos' } }"><span @click=" setTabRequestOrder()">Cotação</span></router-link>
                        <router-link class="menuLink" tag="span" :to="{ name: 'monitors-page', params: { tab: 'pedidos' } }"><span @click="setTabDirectPurchase()">Compra Direta</span></router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="row secondary-header flex-column shadow-sm mb-2" v-if="can('CLIENT_REGISTRATION_REQUEST', 'READ') || can('PROVIDER_REGISTRATION_REQUEST', 'READ')">
            <div class="menus flex-row">
                <div v-if="can('CLIENT_REGISTRATION_REQUEST', 'READ')"
                    @mouseover="isHidden = true" class="menu hvr-underline-from-center" :class="{ active: $route.params.tab == 'cotacoes' }">
                    <router-link :to="{ name: 'clients-registration-requests', params: { tab: 'cotacoes' } }">Solicitações Cliente</router-link>
                </div>
                <div v-if="can('PROVIDER_REGISTRATION_REQUEST', 'READ')"
                    @mouseover="isHidden = true" class="menu hvr-underline-from-center" :class="{ active: $route.params.tab == 'ressuprimento' }">
                    <router-link :to="{ name: 'provider-registration-requests', params: { tab: 'ressuprimento' } }">Solicitações Fornecedor</router-link>
                </div>
            </div>
        </div>
        <div class="row pl-4 not-print env-row" v-if="ENV != 'prod' " :style="{ height: ENV != 'prod' ? '' : '35px' }">
            <div class="col pl-4">
                <p v-if="ENV != 'prod'"><i class="fa fa-flask" aria-hidden="true"></i>
                    Você está em ambiente [ {{ ENV }} ] = [ {{ API_URL }} ]</p>
            </div>
        </div>
    </div> -->

  <div>
    <div class="desktop-header">
      <div class="header-upper-containear not-print">
        <router-link
          :to="{ name: 'monitors-page', params: { tab: 'cotacoes' } }"
          class="header-upper-logo"
        >
          <img
            :class="
              this.whitelabel.data.imgs.img_logo != null
                ? 'logoHeaderStyle'
                : ''
            "
            :src="
              this.whitelabel.data.imgs.img_logo != null
                ? this.whitelabel.data.imgs.img_logo
                : require('@/assets/Logo-BRANCA.svg')
            "
            alt="Logo Clube da Cotação"
          />
          <!-- <img src="@/assets/Logo-BRANCA.svg"> -->
        </router-link>
        <a
          href="https://www.facebook.com/clubdacotacao"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Facebook_black.svg" alt="" />
        </a>
        <a
          href="https://www.instagram.com/clubdacotacao/"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Instagram_black.svg" alt="" />
        </a>
        <a
          href="mailto:contato@clubdacotacao.com.br"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Mail_black.svg" alt="" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCiP8ACo7VDoIzlUwVC5m5Xw"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Youtube_black.svg" alt="" />
        </a>
        <div v-if="present_numbers" class="header-contact-list">
          <div class="header-contact-list-row">
            <i class="fa fa-user" />
            <a
              class="header-contact-list-row-text"
              href="https://api.whatsapp.com/send?phone=5581996490176"
              target="_blank"
            >
              +55 (81) 99649-0176
            </a>
          </div>
          <div class="header-contact-list-row">
            <i class="fa fa-user" />
            <a
              class="header-contact-list-row-text"
              href="https://api.whatsapp.com/send?phone=5581997413889"
              target="_blank"
            >
              +55 (81) 99741-3889
            </a>
          </div>
          <div class="header-contact-list-row">
            <i class="fa fa-user" />
            <a
              class="header-contact-list-row-text"
              href="https://api.whatsapp.com/send?phone=5581997410500"
              target="_blank"
            >
              +55 (81) 99741-0500
            </a>
          </div>
        </div>
        <div class="header-upper-icon-divider">
          <div
            @click="openPage('https://anydesk.com/pt/downloads')"
            class="header-upper-suport-containear"
          >
            <img src="@/assets/Rectangle_32.svg" />
            <div class="header-upper-icon-whats-text">Anydesk</div>
          </div>
        </div>
        <span class="material-icons account-icon">account_circle</span>
        <div class="header-upper-user-text">
          {{ user.user_name }}
        </div>
      </div>
      <div class="header-lower-containear not-print">
        <router-link
          v-if="showMainTop"
          class="menuLink"
          tag="span"
          :to="{
            name: 'clients-registration-requests',
            params: { tab: '/fornecedores/solicitacoes' },
          }"
        >
          <span
            @click="setTabRequestOrder()"
            class="header-lower-texts"
            :class="{
              'header-lower-texts-activate':
                $store.state.tab == 'REQUEST_ORDER',
            }"
          >
            Solicitações Cliente
          </span>
        </router-link>
        <router-link
          class="menuLink"
          tag="span"
          :to="{ name: 'clients-lead', params: { tab: '/fornecedores/lead' } }"
        >
          <span
            @click="setTabLead()"
            class="header-lower-texts"
            :class="{
              'header-lower-texts-activate': $store.state.tab == 'LEAD',
            }"
          >
            Lead
          </span>
        </router-link>
        <router-link
          v-if="showMainTop"
          class="menuLink"
          tag="span"
          :to="{
            name: 'provider-registration-requests',
            params: { tab: '/fornecedores/solicitacoes' },
          }"
        >
          <span
            @click="setTabDirectPurchase()"
            class="header-lower-texts"
            :class="{
              'header-lower-texts-activate':
                $store.state.tab == 'DIRECT_PURCHASE',
            }"
          >
            Solicitações Fornecedor
          </span>
        </router-link>
        <router-link
          v-if="showMainTop"
          class="menuLink"
          tag="span"
          :to="'/contracts/'"
        >
          <span
            @click="setTabContracts()"
            class="header-lower-texts"
            :class="{
              'header-lower-texts-activate': $store.state.tab == 'CONTRACTS',
            }"
          >
            Gestão de contratos
          </span>
        </router-link>
      </div>
    </div>
    <div class="mobile-header">
      <span
        v-on:click="setMobileMenu(true)"
        class="material-icons-round fa-2x mobile-header-menu-icon"
      >
        menu
      </span>
      <div class="mobile-header-logo-containear">
        <router-link
          :to="{ name: 'monitors-page', params: { tab: 'cotacoes' } }"
        >
        <img
            :class="
              this.whitelabel.data.imgs.img_logo !== null
                ? 'logoHeaderStyleMobile'
                : 'mobile-header-img-logo'
            "
            :src="
              this.whitelabel.data.imgs.img_logo !== null
                ? this.whitelabel.data.imgs.img_logo
                : require('@/assets/Logo-BRANCA.svg')
            "
            alt="Logo "
          />
          <!-- <img src="@/assets/Logo-BRANCA.svg" class="mobile-header-img-logo" /> -->
        </router-link>
      </div>
    </div>
    <div  class="header-lower-containear not-print mobile-only">
        <router-link v-if="showMainTop" class="menuLink" tag="span" :to="{ name: 'clients-registration-requests', params: { tab: '/fornecedores/solicitacoes' } }">
          <span @click=" setTabRequestOrder()" class="header-lower-texts" :class="{ 'header-lower-texts-activate': $store.state.tab == 'REQUEST_ORDER' }">
            Cliente
          </span>
        </router-link>
        <router-link class="menuLink" tag="span" :to="{ name: 'clients-lead', params: { tab: '/fornecedores/lead' } }">
          <span @click=" setTabLead()" class="header-lower-texts" :class="{ 'header-lower-texts-activate': $store.state.tab == 'LEAD' }">
            Lead
          </span>
        </router-link>
        <router-link v-if="showMainTop" class="menuLink" tag="span"  :to="{ name: 'provider-registration-requests', params: { tab: '/fornecedores/solicitacoes' } }">
          <span @click="setTabDirectPurchase()" class="header-lower-texts" :class="{ 'header-lower-texts-activate': $store.state.tab == 'DIRECT_PURCHASE' }">
            Fornecedor
          </span>
        </router-link>
        <router-link v-if="showMainTop" class="menuLink" tag="span"  :to="'/contracts/'">
            <span @click="setTabContracts()" class="header-lower-texts" :class="{ 'header-lower-texts-activate': $store.state.tab == 'CONTRACTS' }">
                Contratos
            </span>
        </router-link>
      </div>

    <div v-if="ENV != 'prod'" style="margin-top: 15vh;"  class="row pl-4 not-print env-row">
      <div class="col pl-4">
        <p class="hml-notification-title">
          <i class="fa fa-flask" aria-hidden="true"></i> Você está em ambiente [
          {{ ENV }} ] @ [ {{ API_URL }} ]
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as CONSTANTS from "@/constants/constants";
import Breadcrumb from "@/components/shared/Breadcrumb";
import SessionService from "@/services/TokenService";
import { mapState } from "vuex";
import { gsap } from "gsap";
import { perm_mixin } from "@/mixins/permission.mixin";
import StorageService from "@/services/TokenService";

export default {
  mixins: [perm_mixin],
  components: {
    ccBreadcrumb: Breadcrumb,
  },
  props: ["breadcrumbs", "setMobileMenu"],
  data() {
    return {
      revenda_id: undefined,
      storage_svc: new StorageService(),
      user_name: null,
      ENV: CONSTANTS.PROJECT_ENV,
      API_URL: CONSTANTS.API_URL,
      session: new SessionService(),
      isHidden: true,
      tab: "",
      present_numbers: false,
      showMainTop: false,
    };
  },
  methods: {
    setTabRequestOrder() {
      this.$store.commit("setTabRequestOrder");
      this.tab = this.$store.state.tab;
    },
    setTabLead() {
      this.$store.commit("setTabLead");
      this.tab = this.$store.state.tab;
    },
    setTabDirectPurchase() {
      this.$store.commit("setTabDirectPurchase");
      this.tab = this.$store.state.tab;
    },
    setTabContracts() {
      this.$store.commit("setTabContracts");
      this.tab = this.$store.state.tab;
    },
    openPage(url) {
      window.open(url, "_blank");
    },
  },
  watch: {
    present_numbers(current) {
      if (current) {
        gsap.fromTo(
          "#contacts",
          { x: "100%" },
          { x: "50%", duration: 1, ease: "back.out(1.7)" }
        );
      } else {
        gsap.fromTo(
          "#contacts",
          { x: "0%" },
          { x: "170%", duration: 1, ease: "back.out(1.7)" }
        );
      }
    },
  },
  computed: {
    ...mapState(["user", "whitelabel"]),
  },
  async created() {
    this.perms.forEach((element) => {
      if (element.action == "READ" && element.resource == "MAIN_TOP")
        this.showMainTop = true;
    });
  },
};
</script>

<style lang="scss">
@import "@/sass/commons/_variables";
.env-row {
  background-color: red;
  color: white;
  font-size: 1.5rem;
  font-family: monospace;
  p {
    margin: 0.5rem auto;
    text-align: center;
  }
}

.hml-notification-title {
  background-color: red;
  color: white;
  font-size: 1.5rem;
  font-family: monospace;
}

#app > .header {
  font-family: $font-default;
  & > div {
    display: flex;
    align-items: center;
  }
  z-index: 3;
}
.primary-header {
  background-color: white;
  height: 4rem;
  font-size: 0.9rem;
  color: $primary-color;
  font-weight: 500;
}

.secondary-header {
  background-color: $ternary-color;
  height: 2.3rem;

    .menus {
        display: flex;
        justify-content: space-evenly;
        width: 60%;

        .hvr-underline-from-center:before {
            background: white;
        }

        .menu {
            display: inline-block;
            &.active {
                box-shadow: inset 0px -6px 0px 0px white;
            }
            a {
                display: inline-block;
                padding: 0.5rem;
                color: white;
                font-size: 0.9rem;
                height: 2.3rem;
                font-weight: 500;
            }
            &:hover {
                color: $primary-color;
                cursor: pointer;
            }
        }
    }
}
.mobile-only{
    display: none;
}
.menuDropDown{
    z-index: 1;
    position: absolute !important;
    margin-top: -8px;
    background-color: $ternary-color;
    margin-left: 56px;
    width: 50%;
    span:hover{
        cursor: pointer;
        opacity: 0.8;
    }
}
.menuLink{
    border: 1px solid white!important;
}
.header-upper-containear{
    background: #FF9954;
    height: 11vh;
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    .hvr-underline-from-center:before {
      background: white;
    }

    .menu {
      display: inline-block;
      &.active {
        box-shadow: inset 0px -6px 0px 0px white;
      }
      a {
        display: inline-block;
        padding: 0.5rem;
        color: white;
        font-size: 0.9rem;
        height: 2.3rem;
        font-weight: 500;
      }
      &:hover {
        color: $primary-color;
        cursor: pointer;
      }
    }
  }

.menuDropDown {
  z-index: 1;
  position: absolute !important;
  margin-top: -8px;
  background-color: $ternary-color;
  margin-left: 56px;
  width: 50%;
  span:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.menuLink {
  border: 1px solid white !important;
}
.header-upper-containear {
  background: #ff9954;
  height: 11vh;
  display: flex;
  align-items: center;
}
.header-upper-logo {
  flex: 1;
  margin-left: 50px;
}
.header-upper-icon {
  color: white;
  margin-right: 12px;
  cursor: pointer;
}
.header-upper-icon:hover {
  color: white !important;
  text-decoration: none !important;
  cursor: pointer;
}
.header-upper-icon-insta:hover {
  color: #ff9954 !important;
  text-decoration: none !important;
  cursor: pointer;
}
.header-upper-icon-insta {
  background: white;
  color: #ff9954;
  border-radius: 50%;
  padding: 4px;
  margin-right: 12px;
  cursor: pointer;
}
.header-upper-suport-containear {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 22px;
  margin-right: 12px;
  cursor: pointer;
}
.header-upper-icon-whats {
  color: white;
  background: orangered;
  padding: 5px;
  border-radius: 50%;
}
.header-upper-icon-whats-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ff7110;
  margin-left: 7px;
  margin-right: 10px;
}
.header-upper-icon-divider {
  border-right: 1px solid white;
  margin-right: 15px;
}
.header-upper-user-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-right: 20px;
}
.header-lower-containear {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  color: #605f5f;
  padding-top: 7px;
  padding-bottom: 9px;
  margin-bottom: 11px;
  box-shadow: 0px 5px 8px 0px lightgrey;
  min-height: 50px;
  background-color: white;
  gap: 10vw;
}
.header-lower-texts {
  margin-right: 5vw;
  cursor: pointer;
  color: #605f5f;
  font-size: 16px;
}
.header-lower-texts:hover {
  color: #605f5f !important;
}
.header-lower-texts-activate {
  border-bottom: 3px solid #ff6600;
  padding-bottom: 5.5px;
}
.header-contact-list {
  position: absolute;
  right: 20px;
  top: 100px;
  z-index: 10;
}
.header-contact-list-row {
  background-color: #ff9954;
  border-radius: 5px;
  padding: 2px 4px;
  margin-bottom: 10px;
}
.header-contact-list-row-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: white;
}
.mobile-header {
  display: none;
}
.mobile-header-logo-containear {
  flex: 1;
  display: flex;
  justify-content: center;
}
.mobile-side-menu-containear {
  position: absolute;
  z-index: 3;
  top: 0;
  background-color: white;
  min-width: 100%;
  min-height: 100vh;
}
.account-icon {
  font-size: 35px;
  color: white;
  margin-right: 15px;
}
.header-upper-icons {
  margin-right: 12px;
}
.mobile-header-img-logo {
  width: 130px;
}
.tagnew-icon {
  position: absolute;
  background-color: #30aa4c;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  padding: 0.5vh;
  border-radius: 5px;
  margin-top: -20px;
  margin-left: -1.7vw;
}
.desktop-header {
  position: fixed;
  width: 100%;
  z-index: 3;
}
@media only screen and (max-width: 630px) {
  .desktop-header {
    display: none;
  }
  .mobile-header {
    background: #ff9954;
    height: 8vh;
    display: flex;
    align-items: center;
    z-index: 10;
    padding: 35px 0;
  }
  .mobile-header{
        background: #FF9954;
        height: 8vh;
        display: flex;
        align-items: center;
        z-index: 10;
        padding: 35px 0;
    }
    .mobile-only{
        display: flex;
    }
    .header-lower-texts{
        margin-right: 0;
        text-align: center;
    }
    .menuLink{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.mobile-header-menu-icon {
  margin-left: 15px;
  color: white;
  cursor: pointer;
}

.contact {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  a {
    display: inline-block;
  }
  i {
    font-size: 1.7rem;
    margin-left: 14px;
    cursor: pointer;
  }
  .support {
    cursor: pointer;
    width: 123px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    border: 1px solid #4ac959;
    color: #4ac959;
    border-radius: 5px;
    i {
      margin-right: 4px;
    }
    .support-contacts {
      transform: translateX(160%);
      position: fixed;
      top: 40px;
      right: 142px;
      list-style: none;
      li {
        padding: 6px;
        padding-right: 14px;
        border: 1px solid #4ac959;
        border-radius: 6px;
        background-color: white;
        margin-bottom: 6px;
        &:hover {
          color: white;
          border: 1px solid $primary-color;
          background-color: $primary-color;
          i,
          a {
            color: white;
          }
        }
        i {
          font-size: 1rem;
        }
        a {
          color: #4ac959;
        }
      }
    }
  }
  .support-remote {
    cursor: pointer;
    width: 123px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid red;
    color: red;
    i {
      margin-right: 4px;
    }
  }
}

/* .env-row {
    background-color: $secondary-color;
    color: white;
    font-size: 1.2rem;
    font-family: monospace;
    p {
        color: white;
        margin: 0 auto;
        padding: 5px 0;
        text-align: center;
    }
}

#app > .header {
    font-family: $font-default;
    &>div {
        display: flex;
        align-items: center;
    }
    z-index: 3;
}
.primary-header {
    background-color: white;
    height: 4rem;
    font-size: 0.9rem;
    color: $primary-color;
    font-weight: 500;
}

.secondary-header {
    background-color: $secondary-color;
    height: 2.3rem;

    .menus {
        display: flex;
        justify-content: space-evenly;
        width: 60%;

        .hvr-underline-from-center:before {
            background: white;
        }

        .menu {
            display: inline-block;
            &.active {
                box-shadow: inset 0px -6px 0px 0px white;
            }
            a {
                display: inline-block;
                padding: 0.5rem;
                color: white;
                font-size: 0.9rem;
                height: 2.3rem;
                font-weight: 500;
            }
            &:hover {
                color: $primary-color;
                cursor: pointer;
            }
        }
    }
}
.menuDropDown{
    z-index: 1;
    position: absolute !important;
    margin-top: -8px;
    background-color: $ternary-color;
    margin-left: 56px;
    width: 50%;
    span:hover{
        cursor: pointer;
        opacity: 0.8;
    }
}
.menuLink{
    border: 1px solid white!important;
}

.contact {
    a {
        display: inline-block;
    }
    i {
        font-size: 1.7rem;
        margin-left: 14px;
        cursor: pointer;
    }
    .support {
        cursor: pointer;
        width: 123px;
        height: 100%;
        display: inline-flex;
        align-items: center;
        border: 1px solid #4AC959;
        color: #4AC959;
        border-radius: 5px;
        i {
            margin-right: 4px;
        }
        .support-contacts {
            transform: translateX(160%);
            position: fixed;
            top: 40px;
            right: 142px;
            list-style: none;
            li {
                padding: 6px;
                padding-right: 14px;
                border: 1px solid #4AC959;
                border-radius: 6px;
                background-color: white;
                margin-bottom: 6px;
                &:hover {
                    color: white;
                    border: 1px solid $primary-color;
                    background-color: $primary-color;
                    i, a {
                        color: white;
                    }
                }
                i {
                    font-size: 1rem;
                }
                a {
                   color: #4AC959;
                }
            }
        }
    }
} */
.logoHeaderStyle {
  max-height: 60px;
  border-radius: 12px;
}
.logoHeaderStyleMobile {
  max-height: 50px;
  border-radius: 12px;
}
</style>

