<template>
  <div :class="getClassContainer()"  v-on:click="callAction">
    <span :class="getClassIcon()" class="material-icons">{{iconName}}</span>
    <div :class="getClasstext()" class="">{{text}}</div>
  </div>
</template>

<script>
export default {
  name: 'StandardButton',
  props: ['text', 'iconName', 'action', 'disabled'],
  methods: {
    callAction () {
      if (this.disabled) return
      this.action()
    },
    getClassContainer () {
      if (this.disabled) return 'button-containear-disabled'
      else return 'button-containear'
    },
    getClassIcon () {
      if (this.disabled) return 'button-icon-disabled'
      else return 'button-icon'
    },
    getClasstext () {
      if (this.disabled) return 'button-text-disabled'
      else return 'button-text'
    }
  }
}
</script>

<style scoped>
.button-containear{
  background-color: white;
  border: 0.5px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 7.5vh;
  min-width: 12vw;
  padding: 0 1vw;
}
.button-containear-disabled{
  background-color: white;
  border: 0.5px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.5vh;
  min-width: 12vw;
  padding: 0 1vw;
}
.button-containear:hover{
  background-color: #FF7110;
}
.button-containear:hover > .button-text{
  color: white;
}
.button-containear:hover > .button-icon{
  color: white;
}
.button-text{
  font-weight: 600;
  font-size: 1.1em;
  color: #202020;
}
.button-text-disabled{
  font-weight: 600;
  font-size: 14px;
  color: #E5E5E5;
}
.button-icon{
  color: #FF7110;
  margin-right: 10px;
}
.button-icon-disabled{
  color: #E5E5E5;
  margin-right: 10px;
}
</style>