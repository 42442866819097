<style lang="scss">
// @import '@/sass/commons/_variables';

// .client-sidebar {
//     transition: width .5s ease;
//     white-space: nowrap;
//     height: 100%;
//     width: 5rem;
//     z-index: 2;
//     display: flex;
//     position: fixed;
//     justify-content: center;
//     flex-direction: column;
//     //border-right: 1px solid #ddd;
//     background-color: transparent;
//     .control {
//         height: 50px;
//         margin: 0 0 30px;
//         padding: 0 22px;
//         position: absolute;
//         top: 5.3rem;
//         //background-color: $sidebar-background-color;
//         a {
//             cursor: pointer;
//             .icon {
//                 color: #ffffff;
//                 padding-top: 15px;
//                 display: block;
//                 font-size: 1.5rem;
//                 &.icon-close {
//                     margin-left: 10px;
//                     position: relative;
//                     &::before {
//                         content: '';
//                         transform: rotate(45deg);
//                         width: 2px;
//                         height: 20px;
//                         background-color:#ffffff;
//                         display: block;
//                         position: absolute;
//                     }
//                     &::after {
//                         content: '';
//                         color: $primary-color;
//                         transform: rotate(-45deg);
//                         width: 2px;
//                         height: 20px;
//                         background-color:#ffffff;
//                         display: block;
//                         position: absolute;
//                     }
//                 }
//             }
//         }
//     }
//     ul {
//         position: inherit;
//         list-style: none;
//         padding-top: 0 !important;
//         text-align: center;
//         width: 3.3rem;
//         background-color: transparent;
//         padding: 0px;
//         border-radius: 0 12px 12px 0;
//         margin-top: 20px;
//         li {
//             cursor: pointer;
//             border-radius: 0 4px 4px 0;
//             font-size: 14px;
//             width: 55px;
//             height: 35px;
//             display: flex;
//             align-items: center;
//             padding-right: 7px;
//             padding-left: 5px;
//             border: 1px solid rgba(0, 0, 0, 0);
//             margin: 20px 0;
//             &:hover {
//                 width: 15.3rem;
//                 position: relative;
//                 background-color: #fdf0e9 !important;
//                 z-index: 2;
//                 // -webkit-transition: width 0.2s ease-in-out;
//                 // -moz-transition: width 0.2s ease-in-out;
//                 // -o-transition: width 0.2s ease-in-out;
//                 transition: width 0.3s ease-out;
//                 // border: 1px solid $orange !important;
//                 .abc {
//                     display: flex;
//                     height: 3rem;
//                     width: 20rem;
//                     // color: white;
//                     &:hover {
//                         text-decoration: none;
//                     }
//                 }
//                 p {
//                     display: block !important;
//                 }
//                 .showOnHover{
//                     display: flex;
//                     flex-direction: column;
//                 }
//             }
//             &.active {
//                 //selected border - change this first
//                 background-color: #fdf0e9;
//             }

//             .abc {
//                 width: 100%;
//                 display: flex;
//                 padding-left: 10px;
//                 color: #ff7110;
//                 height: 100%;
//                 align-items: center;
//                 span {
//                     font-size: 18px;
//                     margin-right: 11px;
//                     &::before {
//                         color: #707070;
//                     }
//                     &.fa-medal {
//                         font-size: 1.2rem;
//                         &::before {
//                             color: #ff7110;
//                         }
//                     }
//                     &.icon-icon_home::before {
//                         color: #707070 
//                     }
//                     &.fa-line-chart::before {
//                         color: #707070 
//                     }
//                     &.fa-users::before {
//                         color: #707070 
//                     }
//                     &.fa-shopping-bag::before {
//                         color:#707070
//                     }
//                     &.fa-boxes::before {
//                         color: #707070
//                     }
//                     &.icon-icon_desktop::before {
//                         color: #707070 
//                     }
//                     &.icon-icon_cart_plus::before {
//                         color: #707070 
//                     }
//                     &.icon-icon_sign_out_alt::before {
//                         color: #707070 
//                     }

//                 }
//                 p {
//                     margin: 0;
//                     text-transform: uppercase;
//                     font-weight: 600;
//                     font-size: small;
//                 }
//             }
//         }
//     }
// 	&.expanded {
// 		width: 17rem;
//         ul {
//             width: 15.3rem;
//             // li {
//             //     width: 15.3rem;
//             // }
//         }
// 	}
// }
// .showOnHover{
//     display: none;
// }
// .new-sidebar__list {
//   margin: 0px 10px 4px 10px !important;
//   border-radius: 10px !important;
//   height: 60px !important;
//   background-color: white;
// }
// .new-sidebar__icon-color {
//   color: #707070;
//   font-size: 24px !important;
// }
// .new-sidebar__icon-color:focus {
//   color: red !important;
// }
// .new-sidebar__icon-color:hover {
//   color: #ff7110;
//   transition: ease 0.5s all;
// }
// .new-sidebar__router-link {
//   color: #ff7110 !important;
// }
// .new-sidebar__logout-list {
//   margin: 0px 10px 10px 10px !important;
//   border-radius: 10px !important;
//   height: 60px !important;
//   margin-left: 22px !important;
// }
// .new-sidebar__logout-link {
//   color: #f5222d !important;
//   display: flex;
// }
// .new-sidebar__logout-icon {
//   font-size: 24px !important;
// }
// .new-sidebar__logout-title {
//   color: #ff7110 !important;
//   margin-left: 10px;
// }
// .active-class {
//    color: #ff7110 !important;
// }
.client-sidebar {
    height: 100%;
    top: 7vh;
    z-index: 2;
    display: flex;
    position: fixed;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    font-size: 1vw;
    // display: none;
}
.new-sidebar__list {
  padding: 1vh 1vw;
  display: flex;
  cursor: pointer;
  border-left: 3px solid transparent;
  align-items: center;
  &:hover{
    border-left: 3px solid #ff7110;
    background-color: #fdf0e9;
  }
}
.new-sidebar__icon-color {
  color: #7c7c7c;
  font-size: 2em;
  padding: 0 0.7vw;
  border-radius: 7px;
  display: flex;
  align-items: center;
  height: 5.5vh;
}
.menu-option-2{
    width: 0;
    transition: width 0.15s ease-out;
    overflow: hidden;
    position: absolute;
    margin-left: 5.5vw;
    color: #7c7c7c;
    background-color: #fdf0e9;
    border-radius: 7px;
    padding: 2vh 0;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: -5px;
}
.menu-option-1{
    color: #7c7c7c;
    display: flex;
    align-items: center;
    padding: 0 1.5vw;
    border-radius: 0 7px 7px 0;
    text-transform: uppercase;
    margin-left: -1px;
}
.active-class {color: #ff7110;}
.client-sidebar-arrow-container{
    position: absolute;
    background-color: white;
    margin-left: 5vw;
    margin-bottom: 76vh;
    border-radius: 50%;
    padding: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ff7110;
    box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    &:hover{
        outline: 1px solid #ff7110;
    }
}
.client-sidebar-arrow-container2{
    position: absolute;
    background-color: white;
    margin-left: 25vw;
    margin-bottom: 76vh;
    border-radius: 50%;
    padding: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ff7110;
    box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    &:hover{
        outline: 1px solid #ff7110;
    }
}
.sidebar-home-alert{
    font-weight: 400;
    font-size: 1em;
    background-color: #ff7110;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 0.5vw;
    margin-left: 3vw;
}
.sidebar-options{
    position: absolute;
    margin-left: 22.1vw;
    margin-top: 10vh;
    background-color: white;
    border-radius: 8px;
    padding: 0.5vh 0;
    display: flex;
    flex-direction: column;
    width: max-content;
    outline: 1px solid #ABABAB;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}
.sidebar-option{
    padding: 0.5vh 1vw;
    &:hover{background-color: #fdf0e9;}
}

@media only screen and (max-width: 630px) {
  .client-sidebar {
    display: none;
  }
}
</style>
<template>
    <!-- <div class="client-sidebar" v-bind:class="{ expanded: isExpanded }">
        <ul class="rounded not-print">
            <li class="new-sidebar__list" v-for="menu of permitted_menus" :key="menu.name" v-bind:class="{ active: menu.active, expanded: isExpanded }" @click="activate(menu)">
                <div class="new-sidebar__router-link abc" :to="{ path: menu.link }">
                  <span :class="{ 'active-class' : menu.active}" class="material-icons-outlined new-sidebar__icon-color">
                    {{ menu.icon }}
                  </span> 
                    <div class="showOnHover">
                        <router-link style="color: #ff7110;" :to="{ path: menu.link }">
                            {{menu.name}}
                        </router-link>
                        <router-link class="menu-option-2" :to="{ path: menu.link2 }" style="color: #ff7110;" v-if="menu.name2">
                            {{menu.name2}}
                        </router-link>
                    </div>
                </div>
            </li>
            <li class="new-sidebar__logout-list" v-bind:class="{ expanded: isExpanded }">
              <a class="new-sidebar__logout-link" v-on:click="logout()">
                <span class="material-icons-outlined new-sidebar__logout-icon">
                  logout
                </span>
                <p class="new-sidebar__logout-title" :class="{ 'hidden' : !isExpanded}">Sair</p>
              </a>
            </li>
        </ul>
    </div> -->

    <div class="client-sidebar" :style="getExpandstyles()" @mouseover="showExpand()" @mouseleave="hideExpand()">
        <div class="hover-container" v-for="menu of permitted_menus" :key="menu.name">
            <RouterLink :to="menu.link" v-on:click="redirect(menu)"  class="new-sidebar__list" @mouseover="showMenuOptions(menu)" @mouseleave="hideMenuOptions(menu)">
                <span :class="{ 'active-class' : menu.active, active: menu.active}"  class="material-icons-outlined new-sidebar__icon-color">
                    {{ menu.icon }}
                </span> 
                <div v-if="isExpanded" class="menu-option-1" >
                    {{menu.name}}
                </div>
                <div v-if="isExpanded && menu.name == 'Home'" class="sidebar-home-alert">
                    Começe por Aqui
                </div>
                <span v-if="isExpanded && menu.options" class="material-icons-outlined" style="color: #FF9954;">chevron_right</span>
                <div class="sidebar-options" v-if="menu.showOptions && isExpanded" >
                    <div v-on:click="redirectOptions(option)" class="sidebar-option menu-option-1" v-for="option in menu.options" :key="option.name">
                        {{option.name}}
                    </div>
                </div>
            </RouterLink>
        </div>
        <div class="hover-container">
            <div v-on:click="logout()" class="new-sidebar__list">
                <span class="material-icons-outlined new-sidebar__icon-color">
                    logout
                </span> 
                <div v-if="isExpanded" class="menu-option-1" >
                    sair
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import * as CONSTANTS from "@/constants/constants";
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    mixins: [ perm_mixin ],
    data() {
        return {
            isExpanded: false,
            CONSTANTS: CONSTANTS,
            menus: [
                {
                    name: "Dashboard",
                    resource: '',
                    icon: " donut_large ",
                    link: '/dashboard',
                    active: false,
                    /* action: 'READ_PROVIDERS_MAP',
                    route: 'explore' */
                },
                {
                    name: "Acompanhamento",
                    resource: 'ATTENDANCE',
                    icon: "person",
                    link: '/clientes/acompanhamentos',
                    active: false,
                    /* action: 'READ_PROVIDERS_MAP',
                    route: 'explore' */
                },
                {
                    name: "Clientes",
                    resource: 'CLIENT',
                    icon: "group",
                    link: '/clientes/',
                    active: false,
                    /* route: 'relatorios' */
                },
                {
                    name: "Fornecedores",
                    resource: 'PROVIDER',
                    icon: "groups",
                    link: '/fornecedores/',
                    active: false,
                    /* route: "sellers", */
                },
                {
                    name: "Venda Direta",
                    resource: 'DIRECT_PURCHASE',
                    icon: "add_shopping_cart",
                    link: '/pedidos',
                    route: "products",
                    active: false,
                },
                {
                    name: "Revendas",
                    resource: 'RESALE',
                    icon: "shopping_basket",
                    link: '/resales',
                    /* params: { tab: 'cotacoes' },
                    route: "monitors-page", */
                    active: false,
                },
                {
                    name: "Convites",
                    resource: 'INVITATION',
                    icon: "mail",
                    link: '/convites/',
                    /* route: "campaigns-index", */
                    active: false,
                },
                {
                    name: "Produtos Padrão",
                    icon: "shopping_bag",
                    resource: 'PRODUCT',
                    link: '/produtos/',
                    /* action: 'READ',
                    route: "user-settings", */
                    active: false,
                },
                {
                    name: "Manutenção de Produtos",
                    icon: "engineering",
                    resource: 'MAINTENANCE',
                    link: '/products/maintenance',
                    active: false,
                },
                {
                    name: "Painel de Operações",
                    icon: "desktop_windows",
                    resource: 'REQUEST',
                    link: CONSTANTS.ROUTE_MONITORS,
                    params: { tab: 'cotacoes' },
                    active: false,
                },
                {
                    name: "Configurações de Campanha",
                    icon: "settings",
                    resource: 'SYSCONF',
                    action: 'READ',
                    link: '/settings',
                    active: false,
                },
                {
                    name: "Configurações da Conta",
                    icon: "manage_accounts",
                    resource: 'RESALE_SETTINGS',
                    action: 'READ',
                    link: '/revenda/configuracoes',
                    condition: () => {
                        return this.is_reseller
                    },
                    active: false,
                },
            ]
        };
    },
    computed: {
        permitted_menus: function() {
            return this.menus.filter(menu => menu.link)
                .filter(menu => menu.resource == null || this.can(menu.resource, menu.action || 'READ'))
        }
    },
    watch: {
        '$route' (to, from){
            this.menus.forEach(m => {
                if(to.name == m.route) {
                    this.activate(m)
                }
            })
        }
    },
    methods: {
        hideMenuOptions(menu){if (menu.options) menu.showOptions = false},
        showMenuOptions(menu){
            if (menu.options) menu.showOptions = true
        },
        getExpandstyles() {if (this.isExpanded) return 'border-right: 1px solid #ABABAB; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);'},
        showExpand() {
            this.isExpanded = true
        },
        hideExpand() {
            this.isExpanded = false
            this.menus.forEach(menu => menu.showOptions = false)
        },
        redirectOptions(option) {
            this.isExpanded = false
            this.menus[6].active = true
            this.menus[6].showOptions = false
            this.$router.push(option.link)
        },
        redirect(menu){
            if (!menu.options) {
                this.menus.forEach(m => m.active = false)
                menu.active = true;
                this.$forceUpdate()
                this.isExpanded = false
                // this.$router.push(menu.link)
            }
        },
        logout() {
            this.$store.dispatch('user/logout')
            location.href = CONSTANTS.ROUTE_LOGIN;
        },
        activate(menu) {
            this.menus.forEach(m => m.active = false)
            menu.active = true;
            this.$forceUpdate()
        },
        defineMenuItemActive() {
            this.menus.map((menu,index) => {
                this.menus[index].active = false;
                if ( menu.link === this.$route.path ) {
                    this.menus[index].active = true;
                }
            });
        },
    },
    mounted(){
        this.defineMenuItemActive();
    },
    beforeRouteUpdate (to, from, next) {
        this.defineMenuItemActive();
    }
}
</script>
