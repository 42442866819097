<template>
  <span
    class="label label-default"
    :class="{
        'pedidoGerado clickable pedido-gerado': status == 'PEDIDO GERADO' || status == 'ORDERED',
        'label-warning em-digitacao': status == 'EM DIGITACAO' || status == 'TYPING',
        'label-success digitada': status == 'DIGITADA' || status == 'COTACAO ABERTA' || status == 'DIGITADO' || status == 'CONFIRMADO'  || status == 'TYPED',
        'naodigitada nao-digitada':  status.includes('NÃO DIGITADA') || status.includes('NÃO DIGITADO') || status == 'AGUARDANDO CONFIRMACAO'  || status == 'NOT_TYPED',
        'label-dark':    status == 'SEM PEDIDO' || status == 'NOT_ORDERED',
        'label-danger':  status == 'CANCELADA',
        'label-default':  status == 'P. NÃO TRABALHADOS' || status == 'NOT_OFFERED',
        'freezed':  (status == 'CONGELADA' || status == 'ENVIADA P/ CENTRAL' || status == 'FROZEN'),
        'label-default': status == 'COTACAO CONGELADA'}"
  >
  {{ nomeStatus(status) }}
  <slot></slot>
  </span>
</template>

<script>
export default {
    props: ["status"],
    methods: {
        nomeStatus(status) {
            switch(status) {
                case 'ORDERED':
                    return 'Pedido Gerado'
                case 'TYPED':
                    return 'DIGITADA'
                case 'NOT_TYPED':
                    return 'NÃO DIGITADA'
                case 'NOT_ORDERED':
                    return 'SEM PEDIDO'
                case 'FROZEN':
                    return 'CONGELADA'
                case 'NOT_OFFERED':
                    return 'SEM OFERTA'
                default:
                    return status
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import '@/sass/commons/_variables';

span.label {
  border-radius: 4px;
  padding: 4px 12px !important;
  width: fit-content;
  font-size: 0.9em;
  font-weight: 400;
  display: inline-block;
  min-width: 120px;
  min-height: 23px;
  color: #707d76 !important;
  //box-shadow: 2px 2px lightgray;
  &.em-digitacao {
    background-color: rgba(
      105,
      228,
      166,
      0.2
    ) !important;
  }
  &.nao-digitada {
    //background-color: $orange !important;
    //color: white !important;
    //text-transform: uppercase;
  }
  &.digitada {
    //background-color: $ternary-color !important;
    //color: white !important;
    //text-transform: uppercase;
  }
  &.pedido-gerado {
    //background-color: #3899DC !important;
    //color: white !important;
  }
  &.freezed {
    //border: 1px solid #7b7a7a;
    box-shadow: none;
    background-color: transparent !important;
    color: #808080 !important;
  }
  &.em-digitacao {
    background-color: rgba(
      105,
      228,
      166,
      0.2
    ) !important;
  }
  &.nao-digitada {
    box-shadow: none;
    background-color: transparent !important;
    color: #cd2a2a !important;
    //text-transform: uppercase;
  }
  &.digitada {
    box-shadow: none;
    color: #30aa4c !important;
    //text-transform: uppercase;
  }
  &.pedido-gerado {
    box-shadow: none;
    background-color: transparent !important;
    color: #7683f6 !important;
  }
  &.cancelado {
    background-color: #f4cfcf !important;
  }
}
.naodigitada{
  background-color: #ff7110 !important;
}
.freezed{

}
.pedidoGerado{
  background-color: #3899DC !important;
}
</style>
